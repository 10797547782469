@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
  html {
    font-family: 'Pretendard', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #1f1f1f;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}

@layer components {
  .button1 {
    @apply pl-30 pr-[31px] py-[10.5px] bg-sk-primary-blue rounded-[100px] text-center text-body3-medium lg:text-body1-medium text-neutral-99 select-none cursor-pointer;
  }
  .button2 {
    @apply pl-30 pr-[31px] py-[10.5px] bg-neutral-99 rounded-[100px] text-center text-body3-medium lg:text-body1-medium text-sk-primary-blue select-none cursor-pointer;
  }

  .home-shadowBox {
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
  }

  .service-background {
    background: linear-gradient(180deg, #f7f7f8 0%, #fff 100%);
  }

  .content-posting_background {
    background: linear-gradient(180deg, #f7f7f8 0%, #fff 100%);
  }

  .content-posting_box {
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
  }

  .content-posting_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
